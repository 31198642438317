import React, { useState, useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

// Add preload hints
const preloadChunk = (path) => {
  const link = document.createElement('link');
  link.rel = 'prefetch';
  link.href = path;
  document.head.appendChild(link);
};

// Lazy load components with preloading
const BitcoinChart = React.lazy(() => {
  preloadChunk('/768-dist.js'); // Preload ECharts chunk
  return import('./components/BitcoinChart');
});
const NewsSection = React.lazy(() => import('./components/NewsSection'));
const FunSection = React.lazy(() => import('./components/FunSection'));

const HomePage = () => {
  const [activeContent, setActiveContent] = useState('menu');
  const [feedCount, setFeedCount] = useState(5);
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadFeeds = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/fetch_feeds?count=${feedCount}`);
      const data = await response.json();
      setFeeds(data);
    } catch (error) {
      console.error('Error fetching RSS feeds:', error);
    }
    setLoading(false);
  };

  const showContent = (id) => {
    setActiveContent(id);
    if (id === 'news') {
      loadFeeds();
    }
  };

  // Update preloading logic
  const handleMenuHover = (id) => {
    if (id === 'crypto') {
      // Preload chart components
      Promise.all([
        import('./utils/chartInit'),
        import('./utils/chartComponents'),
        import('echarts/core')
      ]).catch(console.error);
    }
  };

  const MenuButton = ({ id, title, icon, onClick }) => (
    <div className="col-md-4 text-center">
      <a 
        href="#" 
        onClick={(e) => { e.preventDefault(); onClick(id); }}
        onMouseEnter={() => handleMenuHover(id)}
      >
        <div className="home-menu-button">
          <h1>{title}</h1>
          <i className={`bi bi-${icon}`} style={{ fontSize: '2rem' }}></i>
        </div>
      </a>
    </div>
  );

  return (
    <div className="container">
      {activeContent === 'menu' ? (
        <div className="row" id="menu">
          <MenuButton id="news" title="News" icon="newspaper" onClick={showContent} />
          <MenuButton id="crypto" title="Crypto" icon="currency-bitcoin" onClick={showContent} />
          <MenuButton id="fun" title="Fun" icon="emoji-smile" onClick={showContent} />
        </div>
      ) : (
        <div id="content">
          <button onClick={() => setActiveContent('menu')} className="btn btn-sm btn-danger">
            Back
          </button>

          <Suspense fallback={<div className="loading-spinner"></div>}>
            {activeContent === 'news' && (
              <NewsSection
                feedCount={feedCount}
                setFeedCount={setFeedCount}
                loading={loading}
                feeds={feeds}
                loadFeeds={loadFeeds}
              />
            )}

            {activeContent === 'crypto' && (
              <div id="crypto">
                <BitcoinChart />
              </div>
            )}

            {activeContent === 'fun' && <FunSection />}
          </Suspense>
        </div>
      )}
    </div>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('homepage-root');
  if (container) {
    const root = ReactDOM.createRoot(container);
    root.render(<HomePage />);
  }
});
